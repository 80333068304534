// Utils for interacting with YouTube videos
let videoUtils = {
    pauseVideos: function(container) {
        const iframeVideos = container.querySelectorAll('iframe');
        if (iframeVideos.length > 0) {
            iframeVideos.forEach((iframe) => {
                 if (iframe.contentWindow) {
                     // Pause Youtube Videos
                     if (iframe.src.startsWith('https://www.youtube.com')) {
                         iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com');
                     }
                }
            });
        }
    },
    sandboxVideos: function(selector) {
        const iframeSandboxVideos = document.querySelectorAll(selector);
        if (iframeSandboxVideos.length > 0) {
            iframeSandboxVideos.forEach((iframe) => {
                if (!iframe.hasAttribute('sandbox')) {
                    iframe.setAttribute('sandbox','allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox');
                }
            });
        }
    }
};