// get the Prefix for API calls
let APIPrefix = {
    APIUrl: '',
    initialised: false,
    init: function() {
        //removeIf(production)
        if ( window.location.host === 'localhost:3000') {
            this.APIUrl = 'http://localhost:3003';
        }
        //endRemoveIf(production)
        this.initialised = true;
    },
    get: function() {
        if(!this.initialised) {
            this.init();
        }
        return this.APIUrl;
    }
};